<template>
  <article>
    <!-- Início Principal -->
    <v-toolbar dense flat>
      <v-app-bar-nav-icon>
        <v-icon>fas fa-file-upload</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Importar Agregados</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="fecharPagina">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card outlined class="pa-2">
      <v-row dense class="ma-2 mb-1">
        <v-col cols="12" md="5">
          <v-file-input hide-details="auto" background-color="#E3E1D9" outlined dense color="#018656"
          label="Selecione o arquivo" persistent-hint v-model="uploadFiles" hint="Informe o arquivo CSV."
          :loading="carregando" loader-height="4"></v-file-input>
        </v-col>
      </v-row>

      <v-row dense class="justify-end ma-1">
        <v-card-subtitle class="pb-2">
          <a href="/csv_produtos/agregados.csv" download style="text-decoration: none;">
            <v-icon color="#1976d2" small class="mr-2">fas fa-file-upload</v-icon>
            Modelo de arquivo CSV
          </a>
        </v-card-subtitle>

        <v-spacer></v-spacer>

        <v-btn @click="importar()" color="#018454" style="color: white;">
          <v-icon color="white" class="mr-2" small>fas fa-file-upload</v-icon>
          Importar
        </v-btn>
      </v-row>
    </v-card>
  </article>
</template>

<script>
import { fecharPagina } from '../../../functions/fechar_pagina';
import { baseApiUrl, showSuccess, showError } from '@/global';
import axios from "axios";

export default {
  name: "ImportarAgregados",

  data: () => ({
    uploadFiles: [],
    carregando: false,
  }),

  methods: {
    fecharPagina,

    async importar(){
      this.carregando = true;
      let url = `${baseApiUrl}/produtos-importar?tipo=1`;
      
      const data = new FormData();
      data.append('arquivo', this.uploadFiles);

      let config = { header : { 'Content-Type' : 'multipart/form-data' } };

      await axios
        .post(url, data, config)
        .then((res) => {
          if(res.data.erro){
            showError("Não foi possível importar o arquivo");
          }else{
            showSuccess("Arquivo importado com sucesso!");
          }
        }).catch(() => {
          showError("Não foi possível importar o arquivo");
        }).finally(() => {
          this.carregando = false;
        })
    }
  }
}
</script>